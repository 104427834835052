import api from '@/apis/api'
/**
 * 代垫账单相关api
 */
export class subLabelBillApi extends api {

    getList (condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/labelBillList`,
            method: 'post',
            data: condition
        })
    }


  getDealerAll () {
    return this.request({
      url: `${this.servicePath}/${this.modulePath}/getDealerAll`,
      method: 'get',
    })
  }

  exportLabelBills(condition) {
      return this.request({
          url: `${this.servicePath}/${this.modulePath}/exportAll`,
          method: 'post',
          responseType: 'blob',
          data: condition
      })
  }

    exportAllLabelBill(condition) {
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/allExport`,
            method: 'post',
            responseType: 'blob',
            data: condition
        })
    }
}

export default new subLabelBillApi('sub')
